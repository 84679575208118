import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ShadowFrame from "components/shadow-frame";
import Explore from "components/Explore/Explore";
import ShipImg from "assets/images/myassets/Ship_new.png";
import MarketPlaceCard from "components/card/marketplace";
import Loader from "components/Loader/Loader";
import AssetInvite from "./Invite/AssetInvite";
import ComingSoon from "components/popup/comingsoon";

const ShipCard = ({ item, onInviteClick }) => {
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);

  return (
    <NavLink
      className="group relative overflow-hidden rounded-xl lg:rounded-[20px] bg-[#1D1D1D] block h-full"
      to={``}
    >
      <div className="p-3">
        <div className="bg-[#2D2D2D] aspect-[590/298] rounded-lg relative z-10 overflow-hidden">
          <img
            src={item?.img}
            className="h-full w-full rounded-lg object-cover group-hover:scale-105 transition duration-300 ease-in-out"
          />
        </div>
        <div className="flex flex-wrap justify-between relative z-10 gap-5 items-center px-2 lg:px-4 py-4 pb-2">
          <div className="max-w-3xl w-full">
            <h4 className="font-bold text-base lg:text-xl mb-3 md:mb-4">
              {item?.title}
            </h4>
            <p className="leading-sung text-sm text-white/[0.90]">
              {item?.desc}
            </p>

            <div className="__action__button__groups max-sm:[&>button]:text-xs max-sm:[&>button]:px-[20px] max-sm:[&>button]:py-[8px] pt-[20px] flex gap-[16px] [&>button]:w-[50%] [&>button]:py-[12px] [&>button]:rounded-[8px]">
              <button
                // onClick={() => onInviteClick()}
                onClick={() => setShowComingSoonPopup(true)}
                className="border border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000] text-[16px] font-bold font-body"
              >
                Invite Friends
              </button>
              <button
                onClick={() =>
                  setShowComingSoonPopup(true)
                  // (window.location.href =
                  //   `/marketplace/${
                  //     item?.iapCategory === "apartment" ? "apartments" : "ships"
                  //   }/${item?.slug || item?.iapId}/${item?.title
                  //     ?.split(" ")
                  //     .join("-")
                  //     .toLowerCase()}` || null)
                }
                className="border hover:border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000] hover:font-bold text-[16px]"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
        <ComingSoon
          status={showComingSoonPopup}
          text={`We are enhancing the way you and your friends experience ship flights. You'll be able to invite friends to your Ship, explore entertainment, and embark on thrilling ship flights together.`}
          handleData={() => {
            setShowComingSoonPopup(false);
          }}
        />
      </div>
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[50%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </NavLink>
  );
};

const Ships = ({ shipData }) => {
  // This is just a temp variable for testing
  const IsDataAvailable = true;

  const [IsInviteAssets, setinviteAsset] = useState(false);

  const [loading, setLoading] = useState(true);
  const [particularData, setParticularData] = useState(null);
  const [iapId, setIapId] = useState("");

  useEffect(() => {
    if (shipData) {
      setLoading(false);
    }
  }, [shipData]);

  const handleInvite = (iapIdSlug) => {
    setinviteAsset(!IsInviteAssets);
    setIapId(iapIdSlug);
    let filter = shipData.filter((i) => i.iapId === iapIdSlug);
    setParticularData(filter[0]);
  };

  const handelBack = () => {
    setinviteAsset(false);
  };

  return (
    <>
      {/* This __explore_default_button class is a conditional component and it will appears when user don't have any assets */}
      {IsInviteAssets ? (
        <AssetInvite
          type="SHIP"
          iapId={iapId}
          particularData={particularData}
          handelBack={handelBack}
        />
      ) : (
        <>
          {shipData && shipData.length > 0 && (
            <div className="__ships_assets_main pl-[15px] max-[768px]:p-0">
              <div className="__ships_assets_heading mb-[10px] max-[768px]:pl-0">
                <h4 className="font-medium font-heading text-[18px] tracking-[1px] max-[768px]:p-0 max-[768px]:text-[15px]">
                  Ships
                </h4>
              </div>

              <div className="_ships_container grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-4 lg:gap-y-8 mb-14 md:mb-28 mx-auto relative z-10 mt-[30px]">
                {shipData?.map((data) => {
                  return (
                    <ShipCard
                      key={data.id}
                      item={data}
                      onInviteClick={() => handleInvite(data.iapId)}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {!loading && shipData.length === 0 && (
            <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
              <Explore />
              {/* <Loader/> */}
            </div>
          )}
          {loading && !shipData && (
            <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
              <div className="__no_friend_container flex flex-col">
                <h4 className="text-[16px] text-[#7A7A7A] font-body font-medium">
                  <Loader />
                </h4>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Ships;
