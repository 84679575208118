import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Loader from "components/Loader/Loader";
import Explore from "components/Explore/Explore";
import ApartmentImg from "assets/images/myassets/card_img.png";
import MarketPlaceCard from "components/card/marketplace";
import { useGetSingleUserAssetsByTokenQuery } from "store/slicer/userProfile";
import ShadowFrame from "components/shadow-frame";
import AssetInvite from "./Invite/AssetInvite";

const ApartmentCard = ({ item, onInviteClick }) => {
  return (
    <NavLink
      className="group relative overflow-hidden rounded-xl lg:rounded-[20px] bg-[#1D1D1D] block h-full"
      to={``}
    >
      <div className="p-3">
        <div className="bg-[#2D2D2D] aspect-[590/298] rounded-lg relative z-10 overflow-hidden">
          <img
            src={item?.img}
            className="h-full w-full rounded-lg object-cover group-hover:scale-105 transition duration-300 ease-in-out"
          />
        </div>
        <div className="flex flex-wrap justify-between relative z-10 gap-5 items-center px-2 lg:px-4 py-4 pb-2">
          <div className="max-w-3xl w-full">
            <h4 className="font-bold text-base lg:text-xl mb-3 md:mb-4">
              {item?.title}
            </h4>
            <p className="leading-sung text-sm text-white/[0.90]">
              {item?.desc}
            </p>

            <div className="__action__button__groups max-sm:[&>button]:text-xs max-sm:[&>button]:px-[20px] max-sm:[&>button]:py-[8px] pt-[20px] flex gap-[16px] [&>button]:w-[50%] [&>button]:py-[12px] [&>button]:rounded-[8px]">
              <button
                onClick={() => onInviteClick()}
                className="border border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000] text-[16px] font-bold font-body"
              >
                Invite Friends
              </button>
              <button
                onClick={() =>
                  onInviteClick()
                  // (window.location.href =
                  //   `/marketplace/${
                  //     item?.iapCategory === "apartment" ? "apartments" : "ships"
                  //   }/${item?.slug || item?.iapId}/${item?.title
                  //     ?.split(" ")
                  //     .join("-")
                  //     .toLowerCase()}` || null)
                }
                className="border hover:border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000] hover:font-bold text-[16px]"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[50%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </NavLink>
  );
};

const apartmentData = [
  {
    id: "ap-1",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
  {
    id: "ap-2",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
  {
    id: "ap-3",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
  {
    id: "ap-4",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
  {
    id: "ap-5",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
  {
    id: "ap-6",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
  {
    id: "ap-7",
    img: ApartmentImg,
    title: "Nebula Apartment",
    desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
  },
];

const Apartments = ({ apartmentData }) => {
  // const {data, isLoading, error} = useGetSingleUserAssetsByTokenQuery()

  // console.log(data)

  // This is just a temp variable for testing
  // const IsDataAvailable = true;
  const [IsInviteAssets, setinviteAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iapId, setIapId] = useState("");
  const [particularData, setParticularData] = useState(null);

  useEffect(() => {
    if (apartmentData) {
      setLoading(false);
    }
  }, [apartmentData]);

  const handleInvite = (iapIdSlug) => {
    setinviteAsset(!IsInviteAssets);
    setIapId(iapIdSlug);
    let filter = apartmentData.filter((item) => item.iapId === iapIdSlug);
    setParticularData(filter[0]);
  };

  const handelBack = () => {
    setinviteAsset(false);
  };

  return (
    <>
      {/* This __explore_default_button class is a conditional component and it will appears when user don't have any assets */}
      {IsInviteAssets ? (
        <AssetInvite
          type="APARTMENT"
          iapId={iapId}
          particularData={particularData}
          handelBack={handelBack}
        />
      ) : (
        <>
          {apartmentData && apartmentData?.length > 0 && (
            <div className="__apartment_assets_main pl-[15px] max-[768px]:p-0">
              <div className="__apartment_assets_heading mb-[10px] pt-[10px] max-[768px]:pl-0">
                <h4 className="font-medium font-heading text-[18px] tracking-[1px] max-[768px]:p-0 max-[768px]:text-[15px]">
                  Apartments
                </h4>
              </div>

              <div className="_apartment_container grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-4 lg:gap-y-8 mb-14 md:mb-28 mx-auto relative z-10 mt-[30px]">
                {apartmentData?.map((data, index) => {
                  return (
                    <ApartmentCard
                      key={index}
                      item={data}
                      onInviteClick={() => handleInvite(data.iapId)}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {!loading && apartmentData?.length === 0 && (
            <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
              <Explore />
            </div>
          )}
          {loading && !apartmentData && (
            <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
              <div className="__no_friend_container flex flex-col">
                <h4 className="text-[16px] text-[#7A7A7A] font-body font-medium">
                  <Loader />
                </h4>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Apartments;
