import axios from "axios";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "constant/api/Endpoints";

const webRequestHandler = (url) => {
  const isClientSide = typeof window !== "undefined";
  const token = isClientSide ? localStorage.getItem("accessToken") : null;

  const instance = axios.create({
    baseURL: url,
    headers: {
      Authorization: isClientSide && token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    // ... other options
  });
  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Return the response if the status code is 200
      return response.data;
    },
    (error) => {
      // Perform an action if the status code is 401
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        // Here, you can perform any action you need when the status code is 401
        console.error(
          "Request failed with status code:",
          error.response.status
        );
        localStorage.clear();
        window.location.href = "/signin";

        // For example, you can redirect the user, show an error message, etc.
      }

      // Return the rejected Promise to propagate the error
      return Promise.reject(error);
    }
  );
  return instance;
};

export default webRequestHandler;

// // Custom base query with error handling
// export const customBaseQuery = async (args, api, extraOptions) => {
//   const rawBaseQuery = fetchBaseQuery();
//   const result = await rawBaseQuery(args, api, extraOptions);
//   if (result.error && result.error.originalStatus >= 400 && result.error.originalStatus < 500) {
//     // Handle 400-499 errors
//     console.error("Request failed with status code:", result.error.originalStatus);
//     localStorage.clear();
//     window.location.href = "/signin";
//     // Optionally, you can return a new error or result
//     return {
//       error: { status: result.error.originalStatus, message: "Custom error handling" },
//     };
//   }

//   return result;
// };
// Custom base query function that takes a dynamic config object
const customBaseQuery = ({ baseUrl, prepareHeaders }) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: prepareHeaders || ((headers) => headers),
  });
};

// Base query with error handling
export const baseQueryWithReauth =
  (config) => async (args, api, extraOptions) => {
    const baseQuery = customBaseQuery(config);
    let result = await baseQuery(args, api, extraOptions);
    if (
      result.error &&
      result.error.originalStatus >= 400 &&
      result.error.originalStatus < 500
    ) {
      console.error("Request failed with status code:", result.error.status);
      localStorage.clear();
      window.location.href = "/signin";
    }

    return result;
  };
