import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";
/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-Profile-Reducer-functions
 *@developer Aditya P
 */

// Handling State function or logics of User Authentication Module
// Replace the CreateSlice method with RTK function - createAPI for storing api data directly into slicer
export const UserProfile = createApi({
  reducerPath: "UserProfile",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    GetUserProfileByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_USER_PROFILE,
        method: "GET",
      }),
    }),
    UpdateUserInfoDataByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UPD_USER_INFO,
        method: "PATCH",
        body: payload,
      }),
    }),
    ValidateUsername: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.USERNAME_VALIDATOR,
        method: "POST",
        body: payload,
      }),
    }),
    UpdateUsernameWithGenderAndDob: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.ADD_USER_INFO,
        method: "PATCH",
        body: payload,
      }),
    }),
    UpdateUserProfilePhotoByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UPD_USER_PROFILE_PHOTO,
        method: "PATCH",
        body: {
          photoUrl: payload,
        },
      }),
    }),
    RemoveUserProfilePhotoByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REM_USER_PROFILE_PHOTO,
        method: "PATCH",
        body: payload,
      }),
    }),
    UpdateUserPasswordByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UPD_USER_USER_CHANGE_PASSWORD,
        method: "PATCH",
        body: payload,
      }),
    }),
    GetUserPreferenceByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_USER_PREFERENCE,
        method: "GET",
      }),
    }),
    GetUserTagsByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_USER_TAGS,
        method: "GET",
      }),
    }),
    UpdateUserPreferenceByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UPD_USER_PREFERENCE,
        method: "PATCH",
        body: payload,
      }),
    }),
    UpdateUserTagsByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UPD_USER_TAGS,
        method: "PATCH",
        body: {
          tags: payload,
        },
      }),
    }),
    GetUserSuggestedTagsByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_USER_SUGGESTED_TAGS,
        method: "GET",
      }),
    }),
    GetSingleUserAssetsByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_SINGLE_USER_ASSETS,
        method: "GET",
      }),
    }),
    GetSingleUserSubscribedChannel: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_SINGLE_USER_SUBSCRIBED_CHANNEL,
        method: "GET",
      }),
    }),
    UpdateFileName: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.FILE_NAME_GENERATE,
        method: "POST",
        body: {
          mimetype: payload.mimetype,
          path: payload.path,
        },
      }),
    }),
    UpdateUserFilePhoto: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.FILE_USER_UPLOAD,
        method: "POST",
        body: {
          fileId: payload,
        },
      }),
    }),
    UpdateAccountPrivate: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UPDATE_ACCOUNT_PRIVATE,
        method: "PATCH",
        // body: {
        //   fileId: payload,
        // },
      }),
    }),
    DeleteUserOtp: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.DELETE_USER_OTP,
        method: "POST",
        // body: {
        //   fileId: payload,
        // },
      }),
    }),
    DeleteUser: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.DELETE_USER,
        method: "PATCH",
        body: {
          otp:payload.otp,
          password:payload.password
      },
      }),
    }),
  }),
});

export const {
  useGetUserProfileByTokenQuery,
  useUpdateUserInfoDataByTokenMutation,
  useValidateUsernameMutation,
  useUpdateUsernameWithGenderAndDobMutation,
  useRemoveUserProfilePhotoByTokenMutation,
  useUpdateUserProfilePhotoByTokenMutation,
  useUpdateUserPasswordByTokenMutation,
  useGetUserPreferenceByTokenQuery,
  useGetUserTagsByTokenQuery,
  useUpdateUserPreferenceByTokenMutation,
  useUpdateUserTagsByTokenMutation,
  useGetUserSuggestedTagsByTokenQuery,
  useGetSingleUserAssetsByTokenQuery,
  useGetSingleUserSubscribedChannelQuery,
  useUpdateFileNameMutation,
  useUpdateUserFilePhotoMutation,
  useUpdateAccountPrivateMutation,
  useDeleteUserOtpMutation,
  useDeleteUserMutation,
} = UserProfile;
