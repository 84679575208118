import React, { useEffect, useState } from "react";
import PopupWrapper from "../PopupWrapper";
import CurrencyStar from "../../../assets/images/currency-star.png";
import CurrencyUsd from "../../../assets/images/currency-usd.png";
import ComingSoon from "../comingsoon";
import Loader from "components/Loader/Loader";
import { convertUsdToFstrPrice } from "utils";
import { convertUsdToMarketFstrUsd } from "utils";
import { getFstrDiscount, removeTrailingZeroes } from "utils";
import { useNavigate } from "react-router-dom";

const BuyPopupIap = ({
  open,
  onClose,
  canClose,
  onCurrencyChange,
  isCart = true,
  addedToCart = false,
  loading = false,
  actionBtnHandler,
  proceedToCheckoutHandler,
  isSubscription = false,
  priceUsd = 0,
}) => {
  const navigate = useNavigate();
  const [selectedCurrency, setSelectedCurrency] = useState("fstr");
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  useEffect(() => {
    if (onCurrencyChange && typeof onCurrencyChange == "function") {
      onCurrencyChange(selectedCurrency);
    }
  }, [selectedCurrency]);

  // Todo: Temporary comming soon
  const onActionBtnClick = (e) => {
    // if(selectedCurrency === "usd"){
    //   setShowComingSoonPopup(true)
    // } else {
    actionBtnHandler(e);
    // }
  };

  return (
    <>
      <ComingSoon
        status={showComingSoonPopup}
        text={`Payment processing will be going live soon`}
        handleData={() => {
          setShowComingSoonPopup(false);
        }}
      />
      <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
        <div className="relative bg-[#2c2c2c] rounded-[8px] px-4 py-4">
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={onClose}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="28"
                height="28"
                rx="14"
                fill="white"
                fillOpacity="0.1"
              />
              <path
                d="M19.6875 8.3125L8.3125 19.6875M8.3125 8.3125L19.6875 19.6875"
                stroke="white"
                strokeOpacity="0.5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="text-white text-[20px] leading-[25px] font-heading">
            Select Your Payment Method
          </p>

          {/* {isSubscription && (
          <p className="font-normal text-[16px] leading-[28.4px] text-white/70 pt-8 pb-2">
            Unlock the access exclusive content and early releases curated just
            for you. Subscribe by selecting the options below.
          </p>
        )} */}

          <div
            onClick={() => setSelectedCurrency("fstr")}
            className={`__STAR_CONTAINER__ mt-6 p-4 rounded-[8px] flex justify-start gap-2 border ${
              selectedCurrency == "fstr"
                ? "border-[#fbbc5e] bg-[#fbbc5e]/5"
                : "border-[#444444]"
            } cursor-pointer`}
          >
            <img
              className="hidden sm:block h-[60px] w-[60px] rounded-full"
              src={CurrencyStar}
              alt="star"
            />
            <div className="w-full flex justify-between items-stretch">
              <div className="__TEXT_CONTAINER__ max-w-[93%] flex flex-col gap-2 justify-evenly">
                <div className="font-body text-[16px] leading-[19.5px] text-white font-semibold">
                  {isSubscription &&
                    `Subscribe @${removeTrailingZeroes(
                      convertUsdToFstrPrice(priceUsd)
                    )} FSTR `}
                  {isSubscription && (
                    <span className="text-white/50 text-[12px]">
                      (
                      {removeTrailingZeroes(
                        convertUsdToMarketFstrUsd(priceUsd)
                      )}{" "}
                      USD)
                    </span>
                  )}
                  {isSubscription && ` / Month`}

                  {!isSubscription &&
                    `Pay ${removeTrailingZeroes(
                      convertUsdToFstrPrice(priceUsd)
                    )} FSTR `}
                  {!isSubscription && (
                    <span className="text-white/50 text-[12px]">
                      (
                      {removeTrailingZeroes(
                        convertUsdToMarketFstrUsd(priceUsd)
                      )}{" "}
                      USD)
                    </span>
                  )}
                </div>
                <p className="text-[#FFDF6E] font-semibold text-[14px] font-body leading-[17.07px]">
                  (Get {getFstrDiscount()}% Discount)
                </p>
                <p className="text-white/60 text-[16px] leading-[19.5px]">
                  Tokens will be deducted from your wallet upon selection.
                </p>
              </div>
              <div
                className={`__VIRTUAL_SELECT__ self-center shrink-0 !h-[24px] !w-[24px] rounded-full border-[2px] ${
                  selectedCurrency == "fstr"
                    ? "border-[#fbbc5e]"
                    : "border-[#7a7a7a]"
                }`}
              >
                <div
                  className={`h-full w-full rounded-full border-[2px] border-[#252525] ${
                    selectedCurrency == "fstr" ? "bg-[#fbbc5e]" : ""
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setSelectedCurrency("usd")}
            className={`__USD_CONTAINER__ mt-4 p-4 rounded-[8px] flex justify-start gap-2 border ${
              selectedCurrency == "usd"
                ? "border-[#fbbc5e] bg-[#fbbc5e]/5"
                : "border-[#444444]"
            } cursor-pointer`}
          >
            <img
              className="hidden sm:block h-[60px] w-[60px] rounded-full"
              src={CurrencyUsd}
              alt="usd"
            />
            <div className="w-full flex justify-between items-stretch">
              <div className="__TEXT_CONTAINER__ max-w-[93%] flex flex-col gap-2 justify-evenly">
                <div className="font-body text-[16px] leading-[19.5px] text-white font-semibold">
                  {isSubscription && `Subscribe @${priceUsd} USD / Month`}

                  {!isSubscription && `Pay @${priceUsd} USD`}
                </div>
                <p className="text-white/60 text-[16px] leading-[19.5px]">
                  You will be directed to the payment gateway.
                </p>
              </div>
              <div
                className={`__VIRTUAL_SELECT__ self-center shrink-0 !h-[24px] !w-[24px] rounded-full border-[2px] ${
                  selectedCurrency == "usd"
                    ? "border-[#fbbc5e]"
                    : "border-[#7a7a7a]"
                }`}
              >
                <div
                  className={`h-full w-full rounded-full border-[2px] border-[#252525] ${
                    selectedCurrency == "usd" ? "bg-[#fbbc5e]" : ""
                  }`}
                ></div>
              </div>
            </div>
          </div>
          {/* {isCart && !addedToCart && !loading && ( */}
          <div class="mt-8">
            <input
              type="checkbox"
              className="form-checkbox h-[18px] w-[18px] accent-[#FBBC5E] border rounded-md"
              checked={acknowledged}
              onChange={(e) => setAcknowledged(e.target.checked)}
            />
            <span className="text-white/60 text-[16px] leading-[19.5px] mt-[30px]">
              {" "}
              I agree to{" "}
              <a
                href="/refund-cancellation-policy"
                target="_blank"
                className="[text-decoration:underline] text-[#FBBC5E]"
              >
                Content Refund Policy.
              </a>
            </span>
          </div>
          {/* )} */}

          <button
            className={`mt-7 w-full font-bold rounded-lg btn-gradient text-black py-3 px-3 flex justify-center items-center disabled:bg-[#FBBC5E80_!important] disabled:!text-white`}
            type="button"
            onClick={onActionBtnClick}
            // disabled={addedToCart || loading || (isCart && !acknowledged)}
            disabled={addedToCart || loading || !acknowledged}
          >
            {isCart && addedToCart && !loading && (
              <svg
                className="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.3832 6.61604L20.3832 6.61598C20.1488 6.38164 19.8309 6.25 19.4994 6.25C19.168 6.25 18.8501 6.38164 18.6157 6.61598L18.6157 6.61601L9.99943 15.2322L6.38321 11.616L6.38323 11.616L6.38011 11.613C6.14436 11.3853 5.8286 11.2593 5.50086 11.2621C5.17311 11.265 4.8596 11.3964 4.62784 11.6282C4.39608 11.86 4.26461 12.1735 4.26177 12.5012C4.25892 12.829 4.38491 13.1447 4.61261 13.3805L4.61258 13.3805L4.61565 13.3836L9.11565 17.8836L9.11568 17.8836C9.35009 18.1179 9.66798 18.2496 9.99943 18.2496C10.3309 18.2496 10.6488 18.1179 10.8832 17.8836L10.8832 17.8836L20.3832 8.38356L20.3832 8.38354C20.6176 8.14913 20.7492 7.83124 20.7492 7.49979C20.7492 7.16833 20.6176 6.85045 20.3832 6.61604Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                />
              </svg>
            )}
            {isCart && addedToCart && !loading && "Added to Cart"}
            {isCart && !addedToCart && !loading && "Add to Cart"}
            {!isCart && !loading && !isSubscription && "Instant Buy"}
            {!isCart && !loading && isSubscription && "Confirm"}
            {loading && (
              <svg
                aria-hidden="true"
                className=" inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-[#e2931d]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
          </button>

          {isCart && (
            <button
              className="mt-2 w-full font-semibold text-[16px] leading-[26.24px] rounded-lg  text-[#fbbc5e] filter hover:brightness-75 py-3 px-3 flex justify-center items-center"
              onClick={() => navigate("/cart")}
            >
              Proceed to cart!
            </button>
          )}
        </div>
      </PopupWrapper>
    </>
  );
};

export default BuyPopupIap;
