import React, { useState, useEffect } from "react";
import PopupWrapper from "../PopupWrapper";
import { Snackbar } from "@mui/material";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import RollingProfilerLoader from "assets/icons/spinner-white.svg";
import webRequestHandler from "constant/api/apiInstance";

const CreatorInfo = ({ open, onClose, canClose, channelDetail = {} }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const instance = webRequestHandler(BASE_URL);
  const [creatorEmail, setCreatorEmail] = useState(null);
  const [fetchingEmail, setFetchingEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const vertical = "bottom";
  const horizontal = "center";
  console.log(channelDetail);

  const copyToClipboard = (path) => {
    navigator?.clipboard?.writeText(path);
    setOpenSnackbar(true);
  };
  const getEmail = async () => {
    setFetchingEmail(true);
    try {
      const resp = await instance.get(
        EndpointsSlug.GET_CHANNEL_EMAIL(channelDetail?.channelHandle)
      );
      console.log("data", resp.response?.data);
      if (resp?.response?.statusEnum === 1) {
        setCreatorEmail(resp?.response?.data.channelEmail);
        setFetchingEmail(false);
      } else {
        setEmailError("Too many fetches, please try after sometime.");
        setFetchingEmail(false);
      }
    } catch (error) {
      console.log("error", error);
      setEmailError("Something went wrong, please try again later.");
      setFetchingEmail(false);
    }
  };
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="relative  bg-[#2c2c2c] rounded-[8px] py-4 px-4">
        <div
          className="absolute top-1 right-1 bg-white/10 w-[28px] h-[28px] rounded-full grid place-content-center cursor-pointer"
          onClick={onClose}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6875 1.3125L1.3125 12.6875M1.3125 1.3125L12.6875 12.6875"
              stroke="white"
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <h1 className="font-heading font-normal text-[20px] leading-[25px] text-white">
          About Channel
        </h1>
        <div className="__channel__profile__data__section">
          <div className="__profile__data__section mt-5">
            <h4 className="text-lg font-body font-medium">Description</h4>
            <p className="text-lg text-[#FFFFFF80]">
              {channelDetail?.channelDescription}
            </p>
          </div>
          <div className="__profile__data__section mt-5">
            <h4 className="text-lg font-body font-medium">Links</h4>
            {channelDetail?.links?.map((link) => {
              return (
                <div className="mt-2">
                  <p className="text-lg text-[#FFFFFF80]">{link.platform}</p>
                  <a
                    href={link.url}
                    target="_blank"
                    className="text-[#FBBC5E]"
                    rel="noopener noreferrer"
                    key={link.url}
                  >
                    {link.url}
                  </a>
                </div>
              );
            })}
          </div>
          <div className="__profile__data__section mt-5">
            <h4 className="text-lg font-body font-medium">Details</h4>

            <div className="__created__section flex md:gap-[8px]">
              <span className="text-[12px] font-body font-normal text-[#FFFFFF80]">
                Email:
              </span>
              <p className="text-[14px] font-body font-medium w-full">
                {creatorEmail
                  ? creatorEmail
                  : channelDetail?.contactInfo?.contactEmail}{" "}
                {emailError ? (
                  <span className="text-[red]">{emailError}</span>
                ) : (
                  !creatorEmail &&
                  (fetchingEmail ? (
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        display: "inline-block",
                      }}
                      src={RollingProfilerLoader}
                    />
                  ) : (
                    <span className="cursor-pointer" onClick={getEmail}>
                      Show
                    </span>
                  ))
                )}
              </p>
            </div>

            <div className="__subs__section flex md:gap-[8px] pt-[14px]">
              <span className="text-[12px] font-body font-normal text-[#FFFFFF80]">
                Website:
              </span>
              <p className="text-[14px] font-body font-medium">
                {channelDetail?.contactInfo?.websiteName}
              </p>
            </div>

            <div className="__totalSubs__section flex md:gap-[8px] pt-[14px]">
              <span className="text-[12px] font-body font-normal text-[#FFFFFF80]">
                Total Subscribers:
              </span>
              <p className="text-[14px] font-body font-medium">
                {channelDetail?.channelSubscribers || 0}
              </p>
            </div>

            <div className="__totalSubs__section flex md:gap-[8px] pt-[14px]">
              <span className="text-[12px] font-body font-normal text-[#FFFFFF80]">
                Total Content:
              </span>
              <p className="text-[14px] font-body font-medium">
                {channelDetail?.channelContent || 0}
              </p>
            </div>

            <div className="__totalSubs__section flex md:gap-[8px] pt-[14px]">
              <span className="text-[12px] font-body font-normal text-[#FFFFFF80]">
                Country:
              </span>
              <p className="text-[14px] font-body font-medium">
                {channelDetail?.contactInfo?.country.countryName}
              </p>
            </div>
          </div>
          <div className="__profile__data__section mt-5">
            <button
              onClick={() => copyToClipboard(window.location.href)}
              className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.867 12.6697C19 12.3484 19 11.9401 19 11.125C19 10.3099 19 9.90156 18.867 9.58033C18.7791 9.36795 18.6501 9.17497 18.4876 9.01242C18.325 8.84988 18.1321 8.72095 17.9197 8.633C17.5984 8.5 17.1901 8.5 16.375 8.5H11.3C10.32 8.5 9.83 8.5 9.45589 8.69056C9.12631 8.85838 8.85838 9.12631 8.69056 9.45589C8.5 9.82922 8.5 10.3192 8.5 11.3V16.375C8.5 17.1901 8.5 17.5984 8.633 17.9197C8.81111 18.3482 9.15178 18.6897 9.58033 18.867C9.90156 19 10.3099 19 11.125 19C11.9401 19 12.3484 19 12.6697 18.867M18.867 12.6697C18.7791 12.8821 18.6501 13.075 18.4876 13.2376C18.325 13.4001 18.1321 13.5291 17.9197 13.617C17.5984 13.75 17.1901 13.75 16.375 13.75C15.5599 13.75 15.1516 13.75 14.8303 13.883C14.6179 13.9709 14.425 14.0999 14.2624 14.2624C14.0999 14.425 13.9709 14.6179 13.883 14.8303C13.75 15.1516 13.75 15.5599 13.75 16.375C13.75 17.1901 13.75 17.5984 13.617 17.9197C13.5291 18.1321 13.4001 18.325 13.2376 18.4876C13.075 18.6501 12.8821 18.7791 12.6697 18.867M18.867 12.6697C18.4486 14.1124 17.679 15.4288 16.627 16.5011C15.5751 17.5734 14.2736 18.3681 12.8392 18.8141L12.6697 18.867M15.5 8.5V7.8C15.5 6.82 15.5 6.33 15.3094 5.95589C15.1418 5.62643 14.8742 5.35852 14.5449 5.19056C14.17 5 13.68 5 12.7 5H7.8C6.82 5 6.33 5 5.95589 5.19056C5.62631 5.35838 5.35838 5.62631 5.19056 5.95589C5 6.32922 5 6.81922 5 7.8V12.7C5 13.68 5 14.17 5.19056 14.5441C5.35856 14.8739 5.62611 15.1414 5.95589 15.3094C6.32922 15.5 6.82 15.5 7.80078 15.5H8.5"
                  stroke="currentColor"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Copy Link</span>
            </button>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              sx={{ position: "absolute", zIndex: 9999 }}
              open={openSnackbar}
              autoHideDuration={5000}
              onClose={() => setOpenSnackbar(false)}
              message="Copied to clipboard"
            />
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default CreatorInfo;
